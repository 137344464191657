*,*::before,*::after {
  box-sizing: border-box;
}
ul[class],ol[class] {
  padding: 0;
}
body,h1,h2,h3,h4,p,li,figure,figcaption,blockquote,dl,dd {
  margin: 0;
}
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
ul[class],ol[class] {
  list-style: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
img {
  max-width: 100%;
  display: block;
}
section > * + *, article > * + * {
  margin-top: 1em;
}
input,button,textarea,select {
  font: inherit;
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html{
  font-size: 10px;
}
body{
  font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Microsoft YaHei','Microsoft JhengHei',Verdana,'ＭＳ Ｐゴシック',MS-PGothic,sans-serif;
  font-size: 1.4rem;
}
.c-page{
  background-image: url('../img/bg.jpg');
}
.o-container {
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #9B195F;
}
h2{
  color: #756301;
  border-bottom: 2px dotted #756301;
}
h3{
  color: #A82D71;
  border-bottom: 1px solid #A82D71;
}
@media(min-width: 768px){
  .o-container {
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}