@charset "UTF-8";
*, *::before, *::after {
  box-sizing: border-box; }

ul[class], ol[class] {
  padding: 0; }

body, h1, h2, h3, h4, p, li, figure, figcaption, blockquote, dl, dd {
  margin: 0; }

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5; }

ul[class], ol[class] {
  list-style: none; }

a:not([class]) {
  text-decoration-skip-ink: auto; }

img {
  max-width: 100%;
  display: block; }

section > * + *, article > * + * {
  margin-top: 1em; }

input, button, textarea, select {
  font: inherit; }

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important; } }

html {
  font-size: 10px; }

body {
  font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','Microsoft YaHei','Microsoft JhengHei',Verdana,'ＭＳ Ｐゴシック',MS-PGothic,sans-serif;
  font-size: 1.4rem; }

.c-page {
  background-image: url("../img/bg.jpg"); }

.o-container {
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #9B195F; }

h2 {
  color: #756301;
  border-bottom: 2px dotted #756301; }

h3 {
  color: #A82D71;
  border-bottom: 1px solid #A82D71; }

@media (min-width: 768px) {
  .o-container {
    max-width: 920px;
    width: 100%;
    margin-left: auto;
    margin-right: auto; } }

:root {
  --flick-small-text-shadow:
      0 0 1px #fff,
      0 0 2px #fff,
      0 0 5px #fff,
      0 0 11px #fff,
      0 0 20px #fff; }

.c-header__top {
  text-align: center;
  padding: 1.5rem; }
  .c-header__top > a {
    text-decoration: none;
    color: #fff;
    font-size: 1.3rem; }
  .c-header__top span {
    margin-top: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 6.5px;
    font-size: 1.6rem;
    display: block;
    text-shadow: var(--flick-small-text-shadow); }

.b-a, .bar, .bar:before, .bar:after {
  transition: all .25s;
  content: "";
  position: absolute;
  right: 0;
  height: 5px;
  width: 30px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.95); }

.menu-collapsed {
  transition: all .25s;
  position: fixed;
  top: 10px;
  right: 9px;
  height: 36px;
  width: 36px;
  z-index: 1;
  cursor: pointer; }
  .menu-collapsed ul {
    transition: all 0s;
    position: fixed;
    right: -9000px; }

.bar {
  position: fixed;
  right: 10px;
  top: 20px;
  z-index: 20; }
  .bar:before {
    top: -8px; }
  .bar:after {
    top: 8px; }

.b-a-expanded, .menu-expanded .bar:before, .menu-expanded .bar:after {
  transition: all .25s;
  top: -0px; }

.menu-expanded {
  transition: all .25s;
  text-align: center;
  line-height: 130px;
  height: 100%;
  width: 100%;
  border-radius: 0px;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  overflow-y: auto; }
  .menu-expanded ul {
    transition: all 0s;
    position: relative;
    right: 0;
    z-index: 2; }
  .menu-expanded a {
    transition: all .15s;
    text-decoration: none;
    font-size: 2em;
    padding: 5px;
    color: #fff;
    display: block; }
    .menu-expanded a:hover {
      background-color: rgba(255, 255, 255, 0.8);
      transition: all .15s;
      letter-spacing: 2px;
      color: #333;
      border: 1px solid rgba(255, 255, 255, 0.15); }
  .menu-expanded .bar {
    background-color: transparent;
    transition: all .25s; }
    .menu-expanded .bar:before {
      transform: rotate(45deg); }
    .menu-expanded .bar:after {
      transform: rotate(-45deg); }

@media (min-width: 768px) {
  .bar {
    display: none; }
  .menu-collapsed {
    position: static;
    height: auto;
    width: auto;
    cursor: default;
    margin-left: -1rem;
    margin-right: -1rem; }
  .menu-collapsed ul {
    right: 0;
    position: static;
    background-color: #54033b;
    padding-left: 1rem;
    padding-right: 1rem; }
  .c-header__top {
    padding-top: 2rem; }
    .c-header__top a {
      font-size: 1.4rem; }
    .c-header__top span {
      font-size: 2rem; }
  .c-menu[class] {
    margin: 0; }
  .c-menu {
    display: flex; }
    .c-menu__item {
      flex: 1 1 auto;
      text-align: center; }
    .c-menu__link {
      color: #fff;
      text-decoration: none;
      display: inline-block;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; }
      .c-menu__link:hover {
        text-shadow: var(--flick-small-text-shadow); } }

@media (min-width: 992px) {
  .c-header__top {
    background-image: url("../img/header-bg.jpg");
    background-repeat: no-repeat;
    padding: 2.5rem 0 2rem; }
    .c-header__top span {
      font-size: 2.5rem; } }

.c-main__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff; }

.c-gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .c-gallery__item {
    padding: 0.3rem;
    background-color: #fff; }

.c-aside {
  width: 200px;
  margin-right: 2rem;
  flex-shrink: 0; }
  .c-aside__list {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .c-aside__item {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #FFE4ED; }
    .c-aside__item span {
      color: #960900;
      font-size: 1.2rem;
      display: inline-block;
      text-decoration: underline; }
      .c-aside__item span:hover {
        text-decoration: none; }
  .c-aside__item + .c-aside__item {
    margin-top: 1rem; }

.c-hero__inner {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.c-hero img {
  width: 100%;
  height: auto; }

.c-content {
  background-color: #efd7e4;
  padding: 2rem 1rem 3rem;
  width: 100%; }
  .c-content__item {
    margin-bottom: 1rem; }
    .c-content__item span {
      display: block;
      color: #A82D71;
      border-bottom: 1px solid #A82D71;
      font-weight: 700;
      padding: .2rem 0;
      margin-bottom: 0.5rem; }
    .c-content__item a {
      color: #960900;
      text-decoration: none; }
      .c-content__item a:hover {
        text-decoration: underline; }

.c-block {
  display: flex;
  background: url("../img/line_topics.gif") repeat-x bottom;
  padding-bottom: 1.5rem; }
  .c-block__img {
    margin-right: 1.5rem; }
    .c-block__img time {
      display: block;
      color: #fff;
      font-weight: 700;
      text-align: center;
      background-color: #9B195F;
      margin-bottom: 0.5rem; }
  .c-block__title {
    color: #756301;
    font-weight: 700;
    font-size: 1.6rem; }
  .c-block__subtitle a {
    text-decoration: none; }
    .c-block__subtitle a:hover {
      text-decoration: underline; }

iframe {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block; }

.c-table-wrapper {
  overflow-x: auto; }

.c-table td,
.c-table th {
  padding: 10px 0;
  vertical-align: top;
  border-bottom: dotted 1px #000;
  min-width: 100px; }

.c-table th {
  text-align: left; }

.c-table td img {
  float: left;
  margin: 0 10px 0 0;
  padding: 3px;
  border: solid 1px #ddd; }

.log_year {
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px #C57AA4;
  color: #A82D71; }

.c-table--mod {
  width: 100%;
  margin: 0 auto 30px; }
  .c-table--mod th {
    width: 90px;
    color: #A82D71; }
  .c-table--mod th, .c-table--mod td {
    padding: 5px 10px 5px 0;
    border-bottom: dotted 1px #000;
    font-size: 14px;
    font-weight: bold;
    min-width: 100px; }

@media (min-width: 768px) {
  .c-main__inner {
    flex-direction: row;
    align-items: flex-start; }
  .c-aside__list[class] {
    margin-top: 0; } }

.c-footer {
  text-align: center;
  color: #C573A1;
  font-size: 1.2rem; }
  .c-footer__inner {
    padding-bottom: 1.5rem; }

.c-nav[class] {
  margin: 0;
  padding: 2rem 0 1rem; }

.c-nav {
  display: flex;
  align-items: center;
  justify-content: center; }
  .c-nav__item {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem; }
  .c-nav__link {
    text-decoration: none;
    color: #fff; }
