.c-main__inner{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}
.c-gallery{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  &__item{
    padding: 0.3rem;
    background-color: #fff;
  }
}
.c-aside{
  width: 200px;
  margin-right: 2rem;
  flex-shrink: 0;
  &__list{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__item{
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #FFE4ED;
    span{
      color: #960900;
      font-size: 1.2rem;
      display: inline-block;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }
  &__item + &__item{
    margin-top: 1rem;
  }
}
.c-hero{
  &__inner{
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  img{
    width: 100%;
    height: auto;
  }
}
.c-content{
  background-color: #efd7e4;
  padding: 2rem 1rem 3rem;
  width: 100%;
  &__item{
    margin-bottom: 1rem;
    span{
      display: block;
      color: #A82D71;
      border-bottom: 1px solid #A82D71;
      font-weight: 700;
      padding: .2rem 0;
      margin-bottom: 0.5rem;
    }
    a{
      color: #960900;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
.c-block {
  display: flex;
  background: url('../img/line_topics.gif') repeat-x bottom;
  padding-bottom: 1.5rem;
  &__img {
    margin-right: 1.5rem;
    time{
      display: block;
      color: #fff;
      font-weight: 700;
      text-align: center;
      background-color: #9B195F;
      margin-bottom: 0.5rem;
    }
  }
  &__title {
    color: #756301;
    font-weight: 700;
    font-size: 1.6rem;
  }
  &__subtitle {
    a{
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
iframe{
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.c-table-wrapper{
  overflow-x: auto;
}
.c-table td, 
.c-table th{
  padding: 10px 0;
    vertical-align: top;
    border-bottom: dotted 1px #000;
    min-width: 100px;
}
.c-table th{
  text-align: left;
}
.c-table td img{
  float: left;
  margin: 0 10px 0 0;
  padding: 3px;
  border: solid 1px #ddd;
}
.log_year {
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 1px #C57AA4;
  color: #A82D71;
}
.c-table--mod{
  width: 100%;
  margin: 0 auto 30px;
  th{
    width: 90px;
    color: #A82D71;
  }
  th, td{
    padding: 5px 10px 5px 0;
    border-bottom: dotted 1px #000;
    font-size: 14px;
    font-weight: bold;
    min-width: 100px;
  }
}
@media(min-width: 768px){
  .c-main__inner{
    flex-direction: row;
    align-items: flex-start;
  }
  .c-aside__list[class]{
    margin-top: 0;
  }
}