:root {
  --flick-small-text-shadow:
      0 0 1px #fff,
      0 0 2px #fff,
      0 0 5px #fff,
      0 0 11px #fff,
      0 0 20px #fff;
}
.c-header {
  &__top {
    text-align: center;
    padding: 1.5rem;

    > a{
      text-decoration: none;
      color: #fff;
      font-size: 1.3rem;
    }
     span{
      margin-top: 0.5rem;
      text-transform: uppercase;
      letter-spacing: 6.5px;
      font-size: 1.6rem;
      display: block;
      text-shadow: var(--flick-small-text-shadow);

    }
  }
}
.b-a {
  transition: all .25s;
  content: "";
  position: absolute;
  right: 0;
  height: 5px;
  width: 30px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.95);
}
.menu-collapsed {
  transition: all .25s;
  position: fixed;
  top: 10px;
  right: 9px;
  height: 36px;
  width: 36px;
  z-index: 1;
  cursor: pointer;
  ul {
     transition: all 0s;
     position: fixed;
     right: -9000px;
  }
}
.bar {
  @extend .b-a;
  position: fixed;
  right: 10px;
  top: 20px;
  z-index: 20;
  &:before {
     @extend .b-a;
     top: -8px;
  }
  &:after {
     @extend .b-a;
     top: 8px;
  }
}
.b-a-expanded {
  transition: all .25s;
  top: -0px;
}
.menu-expanded {
  transition: all .25s;
  text-align: center;
  line-height: 130px;
  height: 100%;
  width: 100%;
  border-radius: 0px;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  overflow-y: auto;
  ul {
     transition: all 0s;
     position: relative;
     right: 0;
     z-index: 2;
  }
  a {
     transition: all .15s;
     text-decoration: none;
     font-size: 2em;
     padding: 5px;
     color: #fff;
     display:block;
     &:hover {
        background-color:rgba(white, .8);
        transition: all .15s;
        letter-spacing: 2px;
        color:#333;
        border: 1px solid rgba(255, 255, 255, .15);
     }
  }
  .bar {
     background-color: transparent;
     transition: all .25s;
     &:before {
        @extend .b-a-expanded;
        transform: rotate(45deg);
     }
     &:after {
        @extend .b-a-expanded;
        transform: rotate(-45deg);
     }
  }
}
@media(min-width: 768px){
  .bar{
    display: none;
  }
  .menu-collapsed{
    position: static;
    height: auto;
    width: auto;
    cursor: default;
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .menu-collapsed ul{
    right: 0;
    position: static;
    background-color: #54033b;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .c-header{
    &__top{
      padding-top: 2rem;
      a{
        font-size: 1.4rem;
      }
      span{
        font-size: 2rem;
      }
    }
  }
  .c-menu[class]{
    margin: 0;
  }
  .c-menu{
    display: flex;
    &__item{
      flex: 1 1 auto;
      text-align: center;
    }
    &__link{
      color: #fff;
      text-decoration: none;
      display: inline-block;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      &:hover{
        text-shadow: var(--flick-small-text-shadow);
      }
    }
  }
}
@media(min-width: 992px){
  .c-header{
    &__top{
      background-image: url('../img/header-bg.jpg');
      background-repeat: no-repeat;
      padding: 2.5rem 0 2rem;
      span{
        font-size: 2.5rem;
      }
    }
  }
}