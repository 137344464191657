.c-footer {
  text-align: center;
  color: #C573A1;
  font-size: 1.2rem;
  &__inner{
    padding-bottom: 1.5rem;
  }
  &__nav {
  }
}

.c-nav[class]{
  margin: 0;
  padding: 2rem 0 1rem;
}
.c-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  &__item {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  &__link {
    text-decoration: none;
    color: #fff;
  }
}
